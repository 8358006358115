import { useContext, useEffect, useState } from "react";
import {
    useGetModuleProgress,
    useSetModuleFavorite,
    useSetModuleUnfavorite
} from "../../shared/hooks/useModules";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IModuleProps } from "./Module.interface";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import FavoriteButton from "../../components/FavoriteButton/FavoriteButton";
import Placeholder from "./../../shared/assets/imgs/placeholder.svg";
import { markdownToHtml } from "../../shared/helpers/utils";
import Link from "../../components/Link/Link";
import UnitCard from "../../components/UnitCard/UnitCard";

function Module(props: IModuleProps) {
    const outlet = useOutlet();
    const { translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { module, setUpdated, setCurrentModuleId, setModule } = useContext(ModuleContext);
    const { moduleId: id } = useParams();

    const setFavorite = useSetModuleFavorite();
    const setUnfavorite = useSetModuleUnfavorite();

    useEffect(() => {
        setCurrentModuleId(id);
        return () => {
            setCurrentModuleId(undefined);
            setModule(undefined);
        };
    }, [id]);

    const handleFavorite = async () => {
        if (!id || !module) return;
        setIsLoading(true);

        if (!module.isFavorite) await setFavorite.mutateAsync(id);
        else await setUnfavorite.mutateAsync(id);
        setUpdated(new Date());
        setIsLoading(false);
    };

    return (
        <>
            {outlet && <Outlet />}
            {!outlet && (
                <>
                    <div className="h-100 d-flex flex-column pt-2">
                        <div className="container-fluid gx-2 mb-2">
                            <section className="module row g-1">
                                <div className="col-12 d-flex flex-column gap-02">
                                    <div className="d-flex justify-content-between">
                                        <span
                                            className={`color-${module?.tag_color ?? "yellow"}-500 text-bd4-bd text-uppercase`}>
                                            {module?.tag}
                                        </span>
                                        <FavoriteButton
                                            className="ms-auto"
                                            isFavorite={module?.isFavorite}
                                            onClick={handleFavorite}
                                        />
                                    </div>
                                    <h1 className="text-h1-bd">{module?.title}</h1>
                                    <div className="d-flex justify-content-between">
                                        <span className="d-flex gap-01 align-items-center color-neutrals-dark text-bd3-rg">
                                            <i className={`icon icon-16 icon-Time`}></i>
                                            {module?.units?.reduce((a, b) => {
                                                return (a += b.items.reduce((c, d) => {
                                                    return (c += d.duration ?? 0);
                                                }, 0));
                                            }, 0)}
                                            min {translate("common.article", "article")}
                                        </span>
                                        {module?.units && (
                                            <span className="color-neutrals-dark text-bd3-rg">
                                                {`${module?.unitsProgress?.reduce((a, b) => (a += b.completedItems === b.items.length ? 1 : 0), 0)} / ${module?.units?.length} ${
                                                    module?.units?.length > 1
                                                        ? translate("common.units", "units")
                                                        : translate("common.unit", "unit")
                                                }`}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <figure className="col-12">
                                    {/* TODO adicionar parametro no strapi para receber thumbnail do modulo */}
                                    <img src={Placeholder} className="module_thumbnail" />
                                </figure>
                                <div className="col-12 mt-0">
                                    <span
                                        className="module_description color-neutrals-dark text-bd2-rg"
                                        dangerouslySetInnerHTML={{
                                            __html: markdownToHtml(module?.description ?? "")
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <ul className="d-flex flex-column gap-02">
                                        {module?.units?.map((unit, i) => (
                                            <li key={i}>
                                                <UnitCard
                                                    progress={module.unitsProgress?.find(
                                                        (u) => u.id === unit.id
                                                    )}
                                                    unit={unit}
                                                    index={i + 1}
                                                    moduleId={module.id}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </section>
                        </div>
                        <div className="container-fluid bg-neutrals-black px-1 pt-03 mt-auto pb-53">
                            <div className="row gx-0">
                                <div className="col-12">
                                    <Link variant="solid" customCss="w-100" to="/">
                                        Continue your journey
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Module;
