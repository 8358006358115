import { useMutation, useQuery, UseQueryOptions } from "react-query";
import {
    IModule,
    IModuleFavoriteResponse,
    IModuleGetProgressPayload,
    IModuleProgress,
    IModuleSetProgressPayload
} from "../interfaces/modules.interface";
import {
    checkModuleFavorite,
    getFavoriteModules,
    getFavoriteModulesIds,
    getModule,
    getModuleProgress,
    getModules,
    getUnit,
    setModuleFavorite,
    setModuleProgress,
    setModuleUnfavorite
} from "../services/modules.service";

export function useGetModules<T extends any = IModule[]>(
    locale: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["modules", locale],
        queryFn: () => getModules({ locale }).then((r) => r.data),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}
// export function useGetModuleFavorite<T extends any = boolean>(
//     id?: string,
//     options?: UseQueryOptions<any, unknown, T>
// ) {
//     return useQuery({
//         queryKey: ["moduleFavorite", id],
//         queryFn: () => {
//             if (!id) return false;
//             return checkModuleFavorite(id).then((d) =>
//                 d.success ? (d.data as IModuleFavoriteResponse).favourite : false
//             );
//         },
//         retry: 1,
//         refetchOnWindowFocus: false,
//         staleTime: 15 * 60 * 1000,
//         ...options
//     });
// }
export function useGetModuleFavorite() {
    return useMutation((id: string) =>
        checkModuleFavorite(id).then((d) =>
            d.success ? (d.data as IModuleFavoriteResponse).favourite : false
        )
    );
}

export function useGetModuleProgress() {
    return useMutation((payload: IModuleGetProgressPayload) =>
        getModuleProgress(payload).then((d) =>
            d.success ? (d.data as IModuleProgress) : undefined
        )
    );
}

export function useGetModule<T extends any = IModule>(
    documentId?: string,
    locale?: string,
    filters?: any,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["module", documentId, locale, filters],
        queryFn: () => {
            if (!locale) return;

            return getModule({ documentId, locale, filters }).then((r) => r.data);
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}

export function useGetFilteredModules<T extends any = IModule>() {
    return useMutation((payload: { locale?: string; filters?: any }) => getModules(payload));
}

export function useGetUnit<T extends any = IModule[]>(
    id?: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["unit", id],
        queryFn: () => {
            if (!id) return;

            return getUnit(id).then((r) => r.data);
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}

export function useSetModuleProgress() {
    return useMutation((payload: IModuleSetProgressPayload) => setModuleProgress(payload));
}

export function useSetModuleFavorite() {
    return useMutation((payload: string) => setModuleFavorite(payload));
}

export function useSetModuleUnfavorite() {
    return useMutation((payload: string) => setModuleUnfavorite(payload));
}

export function useGetFavoriteModulesIds() {
    return useMutation(() => getFavoriteModulesIds());
}

export function useGetFavorites() {
    return useMutation((payload: { favoritesIds: string[]; locale: string }) =>
        getFavoriteModules(payload)
    );
}
