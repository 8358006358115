import { useCallback, useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IUnitProps } from "./Unit.interface";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { useGetModule, useGetUnit } from "../../shared/hooks/useModules";
import { IModule, IModuleUnit, IUnitItem } from "../../shared/interfaces/modules.interface";
import { routes } from "../../shared/router/routes";
import ComponentPicker from "../../components/UnitComponents/ComponentPicker/ComponentPicker";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import Link from "../../components/Link/Link";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import UnitCard from "../../components/UnitCard/UnitCard";
import { getFromattedPath } from "../../shared/helpers/utils";
import Button from "../../components/Button/Button";
import { useSetUnitFeedback } from "../../shared/hooks/useUser";

function Unit(props: IUnitProps) {
    const navigate = useNavigate();
    const { lang } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { module, setModule, setCurrentUnitId } = useContext(ModuleContext);

    const { unitId: id, moduleId } = useParams();
    const { isLoading, data: unit } = useGetUnit<IModuleUnit>(id);
    const { data: moduleData } = useGetModule<IModule>(moduleId, lang ?? DEFAULT_LANGUAGE);
    const setUserFeedback = useSetUnitFeedback();
    const [hasNext, setHasNext] = useState(true);

    const getIndex = useCallback(() => {
        return (module?.units?.findIndex((unit) => unit.id === id) ?? 0) + 1;
    }, [module, id]);

    const handleUserFeedback = useCallback(
        async (helpful: boolean) => {
            if (!id) return;
            setUserFeedback.mutateAsync({
                unitId: id,
                helpful
            });
        }, [id]);

    useEffect(() => {
        setIsLoading(isLoading || !module);

        if (unit || isLoading) return;

        if (isLoading) return;
        if (!unit) navigate(routes.MODULES.path);

        return;
    }, [isLoading, module, navigate]);

    useEffect(() => {
        if (module) return;

        setModule(moduleData);
    }, [module, moduleData]);

    useEffect(() => {
        setHasNext(!!module?.units?.[getIndex()]);
    }, [getIndex, module]);

    useEffect(() => {
        setCurrentUnitId(id);
    }, [id]);

    return (
        <div className="d-flex flex-column h-100 bg-neutrals-white ">
            <div className="d-flex flex-column h-100">
                <div className="container-fluid gx-2 pt-2 mb-1">
                    <div className="row gx-0">
                        <div className="col-12 d-flex flex-column gap-02">
                            <span className="text-bd3-rg color-neutrals-dark">
                                Unit {getIndex()}
                            </span>
                            <h1 className="text-h2-smbd color-neutrals-black mb-02">
                                {unit?.title}
                            </h1>
                            <p className="text-bd2-rg color-neutrals-dark m-0">
                                {unit?.description}
                            </p>
                        </div>
                    </div>
                </div>
                {unit?.items.map((item, i) => (
                    <ComponentPicker key={i} unitId={id} {...item}></ComponentPicker>
                ))}
            </div>
            {module && module.units?.[getIndex()] && (
                <div className="container-fluid gx-2 bg-blue-50 py-2">
                    <div className="row gx-0">
                        <div className="col-12">
                            <h2 className="text-h4-smbd mb-12">Next module unit</h2>
                            <UnitCard
                                moduleId={module.id}
                                unit={module.units?.[getIndex()]}
                                index={getIndex() + 1}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={`container-fluid gx-2 bg-blue-50 py-2 mt-auto`}>
                <div className="row gx-0">
                    <div className="col-12">
                        <h2 className="text-h4-smbd mb-12">Was this content helpfull?</h2>
                        <div className="d-flex gap-2">
                            <Button
                                onClick={() => handleUserFeedback(true)}
                                type="button"
                                customCss="button_icon color-green-500"
                                icon="Thumbs-up--filled"
                                iconSize={24}
                            />
                            <Button
                                onClick={() => handleUserFeedback(false)}
                                type="button"
                                customCss="button_icon color-green-500"
                                icon="Thumbs-down--filled"
                                iconSize={24}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {hasNext && (
                <div className="container-fluid bg-neutrals-black px-1 pt-03 pb-53">
                    <div className="row gx-0">
                        <div className="col-12">
                            <Link
                                variant="solid"
                                customCss="w-100"
                                to={getFromattedPath(routes.UNIT.path, {
                                    moduleId: moduleId,
                                    unitId: module?.units?.at(getIndex())?.id
                                })}>
                                Next content
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {!hasNext && (
                <div className="container-fluid bg-neutrals-black px-1 pt-03 pb-53">
                    <div className="row gx-0">
                        <div className="col-12">
                            <Link variant="solid" customCss="w-100" to={routes.MODULES.path}>
                                Conclude
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Unit;
