import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { CONTENT_BASE_URL } from "../../../shared/constants";
import { IAudioProps } from "./Audio.interface";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { ItemTypes } from "../../../shared/interfaces/modules.interface";
import { secondsToTimeFormat } from "../../../shared/helpers/utils";
import Link from "../../Link/Link";
import Button from "../../Button/Button";

function Audio(props: IAudioProps) {
    const { url } = props.audio;
    const { lang } = useContext(TranslationContext);
    const audio = useRef<HTMLAudioElement>(null);
    const { currentUnitId, module, setProgress } = useContext(ModuleContext);
    const [percentage, setPercentage] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isChangingTime, setIsChangingTime] = useState(false);
    const [wasPlaying, setWasPlaying] = useState(false);

    useEffect(() => {

        if (!module || !module.unitsProgress) return;
        const unitProgress = module.unitsProgress.find((unit) => unit.id === currentUnitId);
        const itemProgress = unitProgress?.items.find((item) => item.strapiItemId === props.id);

        if (!itemProgress) return;
        const timestamp = Math.round((itemProgress.progress * duration) / 100);
        setPercentage(itemProgress.progress ?? 0);
        setCurrentTime(timestamp);
        (audio.current as HTMLAudioElement).currentTime = timestamp;
    }, [duration, module, currentUnitId]);

    const handleVideoTimestamp = (data: any) => {
        if (isChangingTime) return;
        const currentTime = (data.target as HTMLAudioElement).currentTime;
        setCurrentTime(Math.round(currentTime));
        setPercentage(Math.floor((currentTime / duration) * 100));
    };

    useEffect(() => {
        if (!audio || isPlaying) return;
        let totalDuration = (audio.current as HTMLAudioElement).duration;
        totalDuration = !isNaN(totalDuration) ? totalDuration : 1;
        setDuration(Math.round(totalDuration));
    }, [audio.current?.duration]);

    useEffect(() => {
        if ((currentTime % 10 === 0 || currentTime === duration) && lang && isPlaying) {
            setProgress({
                unitId: props.unitId,
                itemId: props.id,
                progress: percentage,
                itemType: ItemTypes.AUDIO,
                itemLocale: lang
            });
        }
    }, [currentTime, percentage]);

    return (
        <>
            <section className="unit_item container-fluid gx-2">
                <div className="row gx-0">
                    <div className="col-12">
                        <div className="card card_audio px-02 py-03 d-flex gap-1 align-items-center">
                            <div className="audio_action">
                                <Button
                                    type="button"
                                    icon={isPlaying ? "Pause--outline--filled" : "Play--filled"}
                                    iconSize={32}
                                    customCss="color-blue-300 p-0"
                                    onClick={() =>
                                        isPlaying ? audio.current?.pause() : audio.current?.play()
                                    }
                                />
                            </div>
                            <div className="audio_details d-flex flex-column gap-01 w-100">
                                <span className="text-bd2-smbd mb-n01">{props.title}</span>
                                <span className="d-flex color-neutrals-neutral text-bd3-rg align-items-center gap-01 py-01">
                                    <i className="icon icon-12 icon-Time" />
                                    <span>{secondsToTimeFormat(currentTime)}</span>
                                    <span>/</span>
                                    <span>{secondsToTimeFormat(duration)}</span>
                                </span>
                                <div className="progress d-flex gap-02 align-items-center">
                                    <input
                                        type="range"
                                        name="range"
                                        id="range"
                                        min="0"
                                        max="100"
                                        value={percentage}
                                        style={{ "--value": percentage } as CSSProperties}
                                        onTouchEnd={(_) => {
                                            setIsChangingTime(false);
                                            if (wasPlaying)
                                                (audio.current as HTMLAudioElement).play();
                                        }}
                                        onClick={(_) => {
                                            setIsChangingTime(false);
                                            if (wasPlaying)
                                                (audio.current as HTMLAudioElement).play();
                                        }}
                                        onChange={(e) => {
                                            if (!isChangingTime) setWasPlaying(isPlaying);
                                            setIsChangingTime(true);

                                            (audio.current as HTMLAudioElement).pause();
                                            const percentage = Number((e.target as HTMLInputElement).value);
                                            const time = Math.round((percentage * duration) / 100);
                                            setPercentage(percentage);
                                            setCurrentTime(time);
                                            
                                            (audio.current as HTMLAudioElement).currentTime = time;
                                        }}
                                    />
                                    <span className="progress_status text-bd4-md color-neutrals-neutral lh-1">
                                        {percentage}%
                                    </span>
                                </div>
                            </div>
                        </div>

                        <audio
                            onTimeUpdate={handleVideoTimestamp}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            ref={audio}>
                            <source src={`${CONTENT_BASE_URL}${url}`} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                        {!!props.transcript && (
                            <div className="mt-2 d-flex">
                                <Link
                                    target="_blank"
                                    to={CONTENT_BASE_URL + props.transcript.url}
                                    customCss="py-03 flex-grow-1 justify-content-start"
                                    label="Read our audio transcript"
                                    variant="link"
                                    icon="Document"
                                    download
                                />
                                <Link
                                    target="_blank"
                                    to={CONTENT_BASE_URL + props.transcript.url}
                                    variant="link"
                                    icon="Chevron--right"
                                    iconSize={16}
                                    download
                                />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Audio;
