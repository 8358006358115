import { createContext, useCallback, useEffect, useState } from "react";
import { ILoadingContext, ILoadingProps } from "./loading.interface";
import { useIsFetching } from "react-query";
import { delay } from "../../helpers/utils";

const loadingInitValues: ILoadingContext = {
    isLoading: true,
    setIsLoading: Function.prototype(),
    hasLoading: true
};

export const LoadingContext = createContext<ILoadingContext>(loadingInitValues);

const LoadingProvider = ({ children }: ILoadingProps) => {
    const isFetching = useIsFetching();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasLoading, setHasLoading] = useState<boolean>(true);

    const removeScreen = useCallback(async () => {
        if (!isFetching && hasLoading) {
            setHasLoading(false);
        }
    }, [isFetching, hasLoading]);

    useEffect(() => {
        if (isLoading) setHasLoading(true);
        else delay(500).then(removeScreen);
    }, [isLoading, isFetching]);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading, hasLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingProvider;
