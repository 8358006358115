import { Link } from "react-router-dom";
import { UnitCardProps } from "./UnitCard.interface";
import Placeholder from "./../../shared/assets/imgs/placeholder.svg";
import { routes } from "../../shared/router/routes";
import { getFromattedPath } from "../../shared/helpers/utils";
import { useContext, useEffect, useState } from "react";
import { ModuleContext } from "../../shared/providers/module/module.provider";

function UnitCard({ unit, index, moduleId }: UnitCardProps) {
    const { module } = useContext(ModuleContext);
    const [progress, setProgress] = useState(0);
    

    useEffect(() => {
        if(!module) return;
        
        const unitProgress = module.unitsProgress?.find(u => u.id === unit.id);

        if(!unitProgress) return;

        setProgress(unitProgress?.completedItems / unitProgress?.items.length * 100)

    }, [module]);

    return (
        <Link
            className="card card_unit d-flex gap-1 p-02 pe-1"
            to={`${getFromattedPath(routes.UNIT.path, { moduleId, unitId: unit.id })}`}>
            <figure className="unit_thumbnail" data-index={index}>
                <img src={Placeholder} />
            </figure>
            <div className="w-100 d-flex flex-column gap-02">
                <div className="d-flex justify-content-between">
                    <span
                        className={`color-${unit?.tag_color ?? "yellow"}-500 text-bd4-bd text-uppercase`}>
                        {unit.tag}
                    </span>
                    {progress === 100 && (
                        <i className="icon icon-24 icon-Checkmark--filled color-green-500" />
                    )}
                </div>
                <h2 className="text-bd2-bd color-neutrals-black">{unit.title}</h2>
                {progress < 100 && (
                    <div className="d-flex gap-02 align-items-center">
                        <progress
                            className="card_progress"
                            value={progress}
                            max="100"></progress>
                        <span className="text-bd4-md color-neutrals-neutral">{progress}%</span>
                    </div>
                )}
            </div>
        </Link>
    );
}

export default UnitCard;
