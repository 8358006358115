import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { IImageSliderProps } from "./ImageSlider.interface";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { CONTENT_BASE_URL } from "../../../shared/constants";
import { ItemTypes } from "../../../shared/interfaces/modules.interface";

function ImageSlider(props: IImageSliderProps) {
    const { lang } = useContext(TranslationContext);
    const slider = useRef<HTMLDivElement>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const { progress, setProgress } = useContext(ModuleContext);

    const intersectionObserver = useMemo(() => {
        return new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) return;
                    const element = entry.target as HTMLImageElement;
                    setCurrentSlide(Number(element.dataset.index));

                    if (
                        element.parentElement?.lastChild === element &&
                        progress?.progress !== 100 &&
                        lang
                    ) {
                        setProgress({
                            unitId: props.unitId,
                            itemId: props.id,
                            progress: 100,
                            itemType: ItemTypes.IMAGE,
                            itemLocale: lang
                        });
                    }
                });
            },
            { root: document, threshold: 0.8 }
        );
    }, []);

    useEffect(() => {
        if (!slider || !intersectionObserver) return;
        const imgs = slider.current?.querySelectorAll(".slider_image-stage>img");
        imgs?.forEach((img) => intersectionObserver.observe(img));
    }, [slider]);

    return (
        <>
            {!props.content && (
                <section className="unit_item container-fluid gx-0">
                    <div className="row gx-0">
                        <div className="col-12">
                            <h2 className="text-h4-bd px-1 mb-1">{props.title}</h2>
                            <div className="slider_image is_full" ref={slider}>
                                <div className="slider_image-stage">
                                    {props.images.map((img, i) => (
                                        <img
                                            key={i}
                                            src={`${CONTENT_BASE_URL}${img.url}`}
                                            alt={img.alt}
                                            data-index={i}></img>
                                    ))}
                                </div>
                                <div className="slider_image-navigation">
                                    {props.images.map((_, i) => (
                                        <span
                                            key={i}
                                            className={`navigation_item ${i === currentSlide ? "is_active" : ""}`}></span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {props.content && (
                <section className="unit_item no_margins container-fluid gx-2 py-2 pe-0 bg-red-50 ">
                    <div className="row gx-0">
                        <div className="col-12">
                            <h2 className="text-h4-bd mb-1">{props.title}</h2>
                            <p className="text-bd3-rg pe-1 color-neutrals-darker">{props.content}</p>
                            <div className="slider_image" ref={slider}>
                                <div className="slider_image-stage">
                                    {props.images.map((img, i) => (
                                        <img
                                            key={i}
                                            src={`${CONTENT_BASE_URL}${img.url}`}
                                            alt={img.alt}
                                            data-index={i}></img>
                                    ))}
                                </div>
                                <div className="slider_image-navigation">
                                    {props.images.map((_, i) => (
                                        <span
                                            key={i}
                                            className={`navigation_item ${i === currentSlide ? "is_active" : ""}`}></span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default ImageSlider;

function SliderImageContent () {

    return 
}