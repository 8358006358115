import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { IDatContext, IDatProps } from "./dat.interface";
import { IDat, IDatResponse } from "../../interfaces/dats.interface";
import { useCheckDats, useGetDats } from "../../hooks/useDats";
import { AuthenticationContext } from "../authentication/authentication.provider";
import { TranslationContext } from "../translation/translation.provider";

const datInitValues: IDatContext = {
    dats: []
};

export const DatContext = createContext<IDatContext>(datInitValues);

const DatProvider = ({ children }: IDatProps) => {
    const { isAuthenticated, user } = useContext(AuthenticationContext);
    const { lang } = useContext(TranslationContext);

    const [dats, setDats] = useState<IDat[]>([]);

    const getDats = useGetDats();
    const checkDats = useCheckDats();

    const loadDats = useCallback(async () => {
        if (!lang) return;
        // TODO necessário receber o country do user
        const response = await getDats.mutateAsync({ countryIsoCode: "pt", locale: lang });
        if (!response.success) return;

        const dats = response.data as IDat[];
        const datStatus = (
            await Promise.all(
                dats.map((d) =>
                    checkDats
                        .mutateAsync({ id: d.id, frequency: d.frequency, locale: lang })
                        .then((resp) =>
                            resp.success && !(resp.data as IDatResponse).answered ? d : null
                        )
                )
            )
        ).filter((valid) => valid) as IDat[];

        setDats(datStatus);
    }, [checkDats, getDats, lang]);

    useEffect(() => {
        if (!isAuthenticated) return;
        loadDats();
    }, [isAuthenticated, lang]);

    return <DatContext.Provider value={{ dats }}>{children}</DatContext.Provider>;
};

export default DatProvider;
