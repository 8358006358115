import { useContext } from "react";
import Button from "../../Button/Button";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { IDocumentsProps } from "./Documents.interface";
import Link from "../../Link/Link";
import { CONTENT_BASE_URL } from "../../../shared/constants";

function Documents(props: IDocumentsProps) {
    const { translate } = useContext(TranslationContext);

    return (
        <section className="unit_item no_margins container-fluid gx-2 py-2 bg-blue-50 ">
            <div className="row gx-0">
                <div className="col-12">
                    <h2 className="text-h4-bd mb-12">
                        {translate("common.support_materials", "Support materials")}
                    </h2>
                    <article className="card card_documents p-1 bg-neutrals-white">
                        <h3 className="text-h4-bd mb-1">
                            {translate("common.documents", "Documents")}
                        </h3>
                        <ul className="w-100 my-n01">
                            {props.files.map((file, i) => (
                                <li key={i}>
                                    <Link
                                        download={"teste.xlsx"}
                                        to={CONTENT_BASE_URL+file.url}
                                        type="button"
                                        icon="Download"
                                        iconSize={24}
                                        isIconRight
                                        customCss="w-100 justify-content-between px-0 py-02 my-01">
                                        <div className="d-flex flex-column align-items-start gap-02">
                                            <span className="text-bd2-bd text-start">
                                                {file.name}
                                            </span>
                                            <div className="text-bd3-rg color-neutrals-neutral text-uppercase d-flex gap-02">
                                                <span>{file.ext.replace(".", "")}</span>{" "}
                                                <span>{file.size} KB</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </article>
                </div>
            </div>
        </section>
    );
}

export default Documents;
