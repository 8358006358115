import { useContext, useEffect, useRef, useState } from "react";
import { IVideoProps, IVimeoVideo } from "./Video.interface";
import Player from "@vimeo/player";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { ExternalPlatforms, ItemTypes } from "../../../shared/interfaces/modules.interface";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { secondsToTimeFormat } from "../../../shared/helpers/utils";
import { LoadingContext } from "../../../shared/providers/loading/loading.provider";
import Link from "../../Link/Link";
import { CONTENT_BASE_URL } from "../../../shared/constants";

function Video(props: IVideoProps) {
    const { lang } = useContext(TranslationContext);
    const { setProgress, currentUnitId, module } = useContext(ModuleContext);
    const [videoId, setVideoId] = useState<string>();
    const [duration, setDuration] = useState<number>(0);
    const [timestamp, setTimestamp] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const iframe = useRef<HTMLIFrameElement>(null);
    const { setIsLoading } = useContext(LoadingContext);

    useEffect(() => {
        if (!iframe) return;
        setIsLoading(true);

        const player = new Player(iframe.current as HTMLIFrameElement);

        const handleVideoTimestamp = (data: IVimeoVideo) => {
            setPercentage(Math.floor(data.percent * 100));
            setCurrentTime(Math.round(data.seconds));
        };

        const handleVideoLoaded = (data: IVimeoVideo) => {
            player.getDuration().then((duration) => {
                setDuration(duration);
            });

            setIsLoading(false);
        };

        player.on("loaded", handleVideoLoaded);
        player.on("timeupdate", handleVideoTimestamp);

        return () => {
            player.off("timeupdate", handleVideoTimestamp);
        };
    }, []);

    useEffect(() => {
        if (!module || !module.unitsProgress || !iframe.current) return;
        const unitProgress = module.unitsProgress.find((unit) => unit.id === currentUnitId);
        const itemProgress = unitProgress?.items.find((item) => item.strapiItemId === props.id);

        if (!itemProgress) return;
        const timestamp = Math.round((itemProgress.progress * duration) / 100);
        setPercentage(itemProgress.progress ?? 0);


        setCurrentTime(timestamp);
        setTimestamp(timestamp);

        const player = new Player(iframe.current as HTMLIFrameElement);
        player.setCurrentTime(timestamp);
        
    }, [iframe.current, duration, module, currentUnitId]);

    useEffect(() => {
        // UPDATE DB EVERY 10s
        if ((currentTime % 10 === 0 || duration === currentTime) && lang && videoId) {
            setProgress({
                unitId: props.unitId,
                itemId: props.id,
                progress: percentage,
                itemType: ItemTypes.VIDEO,
                itemLocale: lang,
                externalPlatform: ExternalPlatforms.VIMEO,
                externalId: videoId
            });
        }
    }, [currentTime]);

    useEffect(() => {
        const params = props.url.split("/").pop();
        const [id, timestamp] = params?.split("#t=") ?? [];

        setVideoId(id);
        // if (timestamp) setTimestamp(timestamp.replace(/\D/g, ""));
    }, [props.url]);

    return (
        <section className="unit_item container-fluid gx-2">
            <div className="row gx-0">
                <div className="col-12">
                    <span className="d-flex justify-content-end color-neutrals-neutral text-bd4-rg align-items-center gap-01 mb-02">
                        <i className="icon icon-12 icon-Time" />
                        <span>{secondsToTimeFormat(currentTime)}</span>
                        <span>/</span>
                        <span>{secondsToTimeFormat(duration)}</span>
                    </span>
                    <iframe
                        ref={iframe}
                        title={props.title}
                        src={`https://player.vimeo.com/video/${videoId}`}
                        width="640"
                        height="360"
                        style={{ width: "100%", margin: 0, aspectRatio: "16/9", height: "auto" }}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen></iframe>
                    <div className="card d-flex gap-02 align-items-center">
                        <progress className="card_progress" value={percentage} max="100"></progress>
                        <span className="text-bd4-md color-neutrals-neutral">{percentage}%</span>
                    </div>
                    {!!props.transcript && (
                        <div className="mt-2 d-flex">
                            <Link
                                target="_blank"
                                to={CONTENT_BASE_URL + props.transcript.url}
                                customCss="py-03 flex-grow-1 justify-content-start"
                                label="Read our audio transcript"
                                variant="link"
                                icon="Document"
                                download
                            />
                            <Link
                                target="_blank"
                                to={CONTENT_BASE_URL + props.transcript.url}
                                variant="link"
                                icon="Chevron--right"
                                iconSize={16}
                                download
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Video;
