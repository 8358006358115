import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ITranslationContext, ITranslationProps } from "./translation.interface";
import { useTranslation } from "../../hooks/useTranslation";
import { LoadingContext } from "../loading/loading.provider";
import { COOKIES, DEFAULT_LANGUAGE, LOCALE } from "../../constants";
import { getCookies, setCookie } from "../../helpers/utils";
import { IGeneric } from "../../interfaces/generic.interface";

const translationInitValues: ITranslationContext = {
    translations: {},
    translate: () => "",
    lang: "",
    setLang: () => {}
};

export const TranslationContext = createContext<ITranslationContext>(translationInitValues);
export const toTranslate: { [key: string]: { id: string; missing: string[]; fallback: string } } =
    {};

const TranslationProvider = ({ children }: ITranslationProps) => {
    const [lang, setLang] = useState<string | undefined>();
    const { isLoading, data: translations = {} } = useTranslation();
    const { setIsLoading } = useContext(LoadingContext);

    const checkLocale = (id: string, key: string, fallback: string) => {
        // FOR DEVELOPMENT PURPOSES
        // it will add the missing translation to /translations
        // filling the form, it will add the missing translations to strapi

        Object.keys(LOCALE).forEach((loc) => {
            // comment this line to get the translation object filled
            if (translations[key + "-" + LOCALE[loc]]) return;

            Object.assign(toTranslate, {
                [key]: {
                    id,
                    missing: [...(toTranslate[key]?.missing ?? []), LOCALE[loc]],
                    fallback
                }
            });
        });
    };

    const translate = useCallback(
        (key: string, fallback: string) => {
            if (!lang || !Object.keys(translations).length) return "";

            const id = translations[`${key}-id`];
            const val = translations[`${key}-${LOCALE[lang]}`];
            const translated = val ? val : translations[`${key}-${LOCALE[DEFAULT_LANGUAGE]}`];

            checkLocale(id, key, fallback);

            return translated ? translated : fallback ? fallback : `translate key ${key}`;
        },
        [translations, lang]
    );

    useEffect(() => {
        const cookies = getCookies();

        if (cookies.lang !== undefined && (LOCALE as IGeneric)[cookies.lang]) {
            setLang(cookies.lang);
        } else {
            setLang(DEFAULT_LANGUAGE);
        }
    }, []);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (!lang) return;
        setCookie(COOKIES.LANGUAGE, lang);
    }, [lang]);

    return (
        <TranslationContext.Provider value={{ translations, translate, lang, setLang }}>
            {children}
        </TranslationContext.Provider>
    );
};

export default TranslationProvider;
