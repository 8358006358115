import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { IModuleContext, IModuleProps } from "./module.interface";
import { IModule, IProgress, IModuleProgress } from "../../interfaces/modules.interface";
import {
    useGetFavoriteModulesIds,
    useGetModule,
    useGetModuleFavorite,
    useGetModuleProgress,
    useGetModules,
    useSetModuleProgress
} from "../../hooks/useModules";
import { TranslationContext } from "../translation/translation.provider";
import { DEFAULT_LANGUAGE } from "../../constants";
import { LoadingContext } from "../loading/loading.provider";
import { getProgress } from "../../helpers/modules";

const moduleInitValues: IModuleContext = {
    updated: undefined,
    setUpdated: Function.prototype(),
    progress: undefined,
    setProgress: Function.prototype(),
    module: undefined,
    setModule: Function.prototype(),
    modules: [],
    setModules: Function.prototype(),
    currentModuleId: undefined,
    setCurrentModuleId: Function.prototype(),
    currentUnitId: undefined,
    setCurrentUnitId: Function.prototype()
};

export const ModuleContext = createContext<IModuleContext>(moduleInitValues);

const ModuleProvider = ({ children }: IModuleProps) => {
    const { lang } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [updated, setUpdated] = useState<Date>();
    const [progress, setProgress] = useState<IProgress>();
    const [module, setModule] = useState<IModule>();
    const [modules, setModules] = useState<IModule[]>();
    const [currentModuleId, setCurrentModuleId] = useState<string>();
    const [currentUnitId, setCurrentUnitId] = useState<string>();
    const updateProgress = useSetModuleProgress();
    const getFavoriteIds = useGetFavoriteModulesIds();

    const { data: moduleData, isLoading: moduleLoading } = useGetModule<IModule>(
        currentModuleId ?? "",
        lang ?? DEFAULT_LANGUAGE,
        {
            enabled: !!currentModuleId
        }
    );
    const getModuleFavorite = useGetModuleFavorite();
    const getModuleProgress = useGetModuleProgress();

    const {
        refetch: refetchModulesList,
        data: modulesData,
        isLoading: modulesLoading
    } = useGetModules<IModule[]>(lang ?? DEFAULT_LANGUAGE);

    const handleModuleUpdate = async (moduleData: IModule) => {
        let module = moduleData;
        // FAVORITES
        if (!modules) {
            // IF THE CHECK FOR FAVORITES WAS NOT ALREADY MADE
            setIsLoading(true);
            const [isFavorite, moduleProgress] = await Promise.all([
                getModuleFavorite.mutateAsync(moduleData.id),
                getModuleProgress.mutateAsync({
                    moduleId: moduleData.id,
                    locale: lang ?? DEFAULT_LANGUAGE
                })
            ]);

            module = {
                ...module,
                isFavorite,
                moduleProgress: getProgress(moduleProgress),
                unitsProgress: moduleProgress?.units
            };

            setIsLoading(false);
        } else {
            // IF THE CHECK FOR FAVORITES WAS ALREADY MADE
            module = {
                ...module,
                isFavorite: modules.find((m) => m.id === moduleData.id)?.isFavorite,
                moduleProgress: modules.find((m) => m.id === moduleData.id)?.moduleProgress,
                unitsProgress: modules.find((m) => m.id === moduleData.id)?.unitsProgress
            };
        }

        // PROGRESS
        setModule(module);
    };

    const handleModulesListUpdate = async (modulesData: IModule[]) => {
        setIsLoading(true);
        const responseFavorites = await getFavoriteIds.mutateAsync();

        if (responseFavorites.success) {
            const favorites = responseFavorites.data as string[];
            modulesData = modulesData?.map((mod) => ({
                ...mod,
                isFavorite: favorites.indexOf(mod.id) >= 0
            }));
        }

        const modulesProgress = await Promise.all(
            modulesData.map((mod: IModule) =>
                getModuleProgress.mutateAsync({
                    moduleId: mod.id,
                    locale: lang ?? DEFAULT_LANGUAGE
                })
            )
        );

        modulesData = modulesData.map((mod: IModule, i: number) => ({
            ...mod,
            moduleProgress: getProgress(modulesProgress[i]),
            unitsProgress: modulesProgress[i]?.units
        }));

        setModules(modulesData);
        setIsLoading(false);
    };

    useEffect(() => {
        const isLoading = modulesLoading || moduleLoading || !modules;

        setIsLoading(isLoading);
    }, [modulesLoading, moduleLoading]);

    useEffect(() => {
        // SET THE MODULES LIST COMBINED WITH FAVORITES & PROGRESS

        if (modulesLoading || !modulesData) return;

        handleModulesListUpdate(modulesData);
    }, [modulesData, modulesLoading, updated]);

    useEffect(() => {
        // GET THE MODULES LIST AFTER UPDATED ACTION
        refetchModulesList();
    }, [updated]);

    useEffect(() => {
        // SET THE CURRENT MODULE DATA
        if (!moduleData || modulesLoading) return;

        handleModuleUpdate(moduleData);
    }, [moduleData, modulesLoading]);

    useEffect(() => {
        // UPDATES MODULE PROGRESS
        if (!module?.id || progress?.progress === 1) return;
        console.log("progress updating...", progress);

        updateProgress.mutateAsync({ ...(progress as IProgress), moduleId: module.id });
    }, [progress]);

    return (
        <ModuleContext.Provider
            value={{
                updated,
                setUpdated,
                progress,
                setProgress,
                module,
                setModule,
                modules,
                setModules,
                currentModuleId,
                setCurrentModuleId,
                currentUnitId,
                setCurrentUnitId
            }}>
            {children}
        </ModuleContext.Provider>
    );
};

export default ModuleProvider;
