import { IModuleProgress } from "../interfaces/modules.interface";

export const getProgress = (moduleProgress?: IModuleProgress) => {
    if (!moduleProgress) return 0;

    const progressStatus = moduleProgress.units.reduce(
        (a: { total: number; completed: number }, unit) => {
            return {
                total: a.total + unit.items.length,
                completed: a.completed + unit.completedItems
            };
        },
        {
            total: 0,
            completed: 0
        }
    );

    return Math.ceil(progressStatus.completed / (progressStatus.total ?? 1));
};
