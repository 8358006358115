import { IModuleCardProps } from "./ModuleCard.interface";
import Placeholder from "./../../shared/assets/imgs/placeholder.svg";
import { Link } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { useContext } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import { useSetModuleFavorite, useSetModuleUnfavorite } from "../../shared/hooks/useModules";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import { getFromattedPath } from "../../shared/helpers/utils";

function ModuleCard(module: IModuleCardProps) {
    const { translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { setUpdated } = useContext(ModuleContext);

    const setFavorite = useSetModuleFavorite();
    const setUnfavorite = useSetModuleUnfavorite();

    const handleFavorite = async () => {
        setIsLoading(true);

        if (!module.isFavorite) await setFavorite.mutateAsync(module.id);
        else await setUnfavorite.mutateAsync(module.id);
        setUpdated(new Date());
        setIsLoading(false);
    };

    return (
        <article className="card card_module d-flex flex-column gap-1 h-100">
            <Link to={getFromattedPath(routes.MODULE.path, { moduleId: module.id })}>
                <figure className="card_thumbnail">
                    <img src={Placeholder} alt={module.title} />
                </figure>
            </Link>
            <div className="card_info px-1 d-flex flex-column gap-1 h-100">
                <Link
                    to={getFromattedPath(routes.MODULE.path, { moduleId: module.id })}
                    className="d-flex flex-column gap-1">
                    <h3 className="text-h5-smbd color-neutrals-black">{module.title}</h3>
                    {!!module.moduleProgress && module.moduleProgress < 1 && (
                        <div className="my-01 d-flex gap-02 align-items-center">
                            <progress
                                className="card_progress"
                                value={(module.moduleProgress ?? 0) * 100}
                                max="100"></progress>
                            <span className="text-bd4-md color-neutrals-neutral">
                                {(module.moduleProgress ?? 0) * 100}%
                            </span>
                        </div>
                    )}
                </Link>
                <div className="d-flex justify-content-between gap-1 py-1 mt-auto">
                    {!!module.contentAmount && (
                        <span className="text-bd2-rg color-neutrals-neutral">
                            {`${module.contentAmount} ${
                                module.contentAmount > 1
                                    ? translate("common.units", "units")
                                    : translate("common.unit", "unit")
                            }`}
                        </span>
                    )}
                    <FavoriteButton
                        className="ms-auto"
                        isFavorite={module?.isFavorite}
                        onClick={handleFavorite}
                    />
                </div>
            </div>
        </article>
    );
}

export default ModuleCard;
