import { APP_CONFIGS } from "../constants";
import { IRequest, IRequestError } from "../interfaces/authentication.interface";
import { request } from "./requests.service";

export const setUserFeedback = async (payload: {
    unitId: string;
    helpful: boolean;
}): Promise<IRequest<null | IRequestError>> => {
    try {
        const { unitId, helpful } = payload;
        await request.post(`/${APP_CONFIGS.api.base}/content/module/feedback/${unitId}`, {
            helpful
        });
        return { success: true, data: null };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};
