import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IUser, IUserSettings } from "../../interfaces/user.interface";
import { AuthenticationContext } from "../authentication/authentication.provider";
import { IUserContext, IUserProps } from "./user.interface";
import { useGetUserSettings, useUnlockActivityGoals } from "../../hooks/useUser";
import { useGetFilteredModules, useGetModuleProgress } from "../../hooks/useModules";
import { TranslationContext } from "../translation/translation.provider";
import { DEFAULT_LANGUAGE } from "../../constants";
import { getProgress } from "../../helpers/modules";
import { LoadingContext } from "../loading/loading.provider";

const userInitValues = {
    user: undefined,
    userSettings: undefined,
    setUser: Function.prototype()
};

export const UserContext = createContext<IUserContext>(userInitValues);

const UserProvider = ({ children }: IUserProps) => {
    const BASE_MODULE_LIMIT = useMemo(() => 0.8, []);
    const { setIsLoading } = useContext(LoadingContext);
    const { lang } = useContext(TranslationContext);
    const { user: userData } = useContext(AuthenticationContext);
    const [user, setUser] = useState<IUser>();
    const [userSettings, setUserSettings] = useState<IUserSettings>();

    const getUserSettings = useGetUserSettings();
    const getModule = useGetFilteredModules();
    const getModuleProgress = useGetModuleProgress();
    const unlockActivityGoals = useUnlockActivityGoals();

    const getEducationalModule = useCallback(async () => {
        const response = await getModule.mutateAsync({
            filters: {
                type: {
                    eq: "PSYCOEDUCATIONAL"
                }
            }
        });

        if (!response.success) return;

        const [educationalModule] = response.data;

        const progressData = await getModuleProgress.mutateAsync({
            moduleId: educationalModule.id,
            locale: lang ?? DEFAULT_LANGUAGE
        });
        const progress = getProgress(progressData);

        if (progress < BASE_MODULE_LIMIT) return;

        await unlockActivityGoals.mutateAsync();
    }, []);

    useEffect(() => {
        const isLoading =
            getUserSettings.isLoading ||
            getModule.isLoading ||
            getModuleProgress.isLoading ||
            unlockActivityGoals.isLoading;
        setIsLoading(isLoading);
    }, [
        getUserSettings.isLoading,
        getModule.isLoading,
        getModuleProgress.isLoading,
        unlockActivityGoals.isLoading
    ]);

    const checkActivityGoals = useCallback(async () => {
        const response = await getUserSettings.mutateAsync();

        if (!response.success) return;

        const settings = response.data as IUserSettings;
        setUserSettings(settings);

        if (settings.myActivityGoalsUnlocked) return;

        getEducationalModule();
    }, [userData]);

    useEffect(() => {
        checkActivityGoals();
    }, [user]);

    useEffect(() => {
        setUser(user);
    }, [userData]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                userSettings
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
