import { useMutation } from "react-query";
import { getUserDetails, getUserSettings, setUserSettings, unlockActivityGoals } from "../services/user.service";
import { setUserFeedback } from "../services/feedback.service";

export function useGetUser() {
    return useMutation(getUserDetails);
}

export function useGetUserSettings() {
    return useMutation(getUserSettings);
}

export function useSetUserSettings() {
    return useMutation(setUserSettings);
}

export function useSetUnitFeedback() {
    return useMutation(setUserFeedback);
}

export function useUnlockActivityGoals() {
    return useMutation(unlockActivityGoals);
}