import React from "react";
import ReactDOM from "react-dom/client";
import "./shared/styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./shared/router/router";
import AuthenticationProvider from "./shared/providers/authentication/authentication.provider";
import LoadingProvider from "./shared/providers/loading/loading.provider";
import { QueryClient, QueryClientProvider } from "react-query";
import TranslationProvider from "./shared/providers/translation/translation.provider";
import DatProvider from "./shared/providers/dat/dat.provider";
import { ReactQueryDevtools } from "react-query/devtools";
import UserProvider from "./shared/providers/user/user.provider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient();

root.render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <LoadingProvider>
            <AuthenticationProvider>
                <TranslationProvider>
                    <UserProvider>
                        <DatProvider>
                            <RouterProvider router={router} />
                        </DatProvider>
                    </UserProvider>
                </TranslationProvider>
            </AuthenticationProvider>
        </LoadingProvider>

        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
