import { ICookies } from "../interfaces/cookies.interface";

export const setCookie = (
    name: any,
    value: any,
    hours?: any,
    path = "/",
    domain?: any,
    secure?: any
) => {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (hours !== undefined && hours !== null) {
        const date = new Date();
        date.setTime(date.getTime() + hours * 60 * 60 * 1000);
        cookieString += `; expires=${date.toUTCString()}`;
    }

    cookieString += `; path=${path}`;

    if (domain) {
        cookieString += `; domain=${domain}`;
    }

    if (secure) {
        cookieString += `; secure`;
    }
    
    document.cookie = cookieString;
};

export const getCookies = (): ICookies => {
    const cookies = document.cookie;
    const cookieArray = cookies.split(";");
    const cookieObject = {};

    cookieArray.forEach((cookie) => {
        let [name, value] = cookie.split("=");
        name = name.trim();
        value = value ? value.trim() : "";
        Object.assign(cookieObject, {
            ...cookieObject,
            [name]: decodeURIComponent(value)
        });
    });

    return cookieObject;
};

export const clearCookies = (): ICookies => {
    const cookies = document.cookie;
    const cookieArray = cookies.split(";");
    const cookieObject = {};

    cookieArray.forEach((cookie) => {
        let [name] = cookie.split("=");
        name = name.trim();
        setCookie(name, null, 0);
    });

    return cookieObject;
};

export const markdownToHtml = (markdown: string) => {
    // Convert headers
    markdown = markdown.replace(/^###### (.*$)/gim, "<h6>$1</h6>");
    markdown = markdown.replace(/^##### (.*$)/gim, "<h5>$1</h5>");
    markdown = markdown.replace(/^#### (.*$)/gim, "<h4>$1</h4>");
    markdown = markdown.replace(/^### (.*$)/gim, "<h3>$1</h3>");
    markdown = markdown.replace(/^## (.*$)/gim, "<h2>$1</h2>");
    markdown = markdown.replace(/^# (.*$)/gim, "<h1>$1</h1>");

    // Convert bold text
    markdown = markdown.replace(/\*\*(.*?)\*\*/gim, "<strong>$1</strong>");

    // Convert italic text
    markdown = markdown.replace(/\*(.*?)\*/gim, "<em>$1</em>");

    // Convert images ![alt text](image url)
    markdown = markdown.replace(/!\[([^\]]*)\]\(([^)]+)\)/gim, "<img alt='$1' src='$2' />");

    // Convert links [link text](link url)
    markdown = markdown.replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>");

    // Convert unordered lists
    markdown = markdown.replace(/^\s*-\s+(.*$)/gim, "<li>$1</li>");
    markdown = markdown.replace(/(<li>.*<\/li>)/gim, "<ul>$1</ul>");

    // Convert paragraphs
    markdown = markdown.replace(/^\s*(?!<h\d>|<ul>|<li>|<img>|<a>)(.+)$/gim, "<p>$1</p>");

    // Remove any trailing newlines
    return markdown.trim();
};

export const delay = async (time: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(() => resolve(), time));
};

interface IDateParts {
    YYYY: string;
    M: string;
    MM: string;
    d: string;
    dd: string;
    HH: string;
    mm: string;
    ms: string;
    [key: string]: string;
}

export const getDateParts = (date: string | Date): IDateParts => {
    let d: Date;

    if (typeof date === "string") {
        d = new Date(date);
    } else {
        d = date;
    }

    const month = d.getUTCMonth() + 1;
    const day = d.getUTCDate();
    const hour = d.getUTCHours();
    const minutes = d.getUTCMinutes();
    const seconds = d.getUTCSeconds();
    const milliseconds = d.getUTCMilliseconds();

    return {
        YYYY: `${d.getFullYear()}`,
        MMMM: new Intl.DateTimeFormat("pt-PT", { month: "long" }).format(d),
        MM: month < 10 ? `0${month}` : `${month}`,
        M: `${month}`,
        dd: day < 10 ? `0${day}` : `${day}`,
        d: `${day}`,
        HH: hour < 10 ? `0${hour}` : `${hour}`,
        mm: minutes < 10 ? `0${minutes}` : `${minutes}`,
        ss: seconds < 10 ? `0${seconds}` : `${seconds}`,
        ms: milliseconds < 10 ? `0${milliseconds}` : `${milliseconds}`
    };
};

export const formatDate = (date: string | Date, format: string) => {
    const dateParts = getDateParts(date);

    let formatArray = format;

    Object.keys(dateParts).forEach((k) => {
        formatArray = formatArray.replaceAll(k, dateParts[k]);
    });

    return formatArray;
};

export const secondsToTimeFormat = (sec: number) => {
    return `${Math.floor(sec / 60) < 10 ? "0" + Math.floor(sec / 60) : Math.floor(sec / 60)}:${Math.floor(sec % 60) < 10 ? "0" + Math.floor(sec % 60) : Math.floor(sec % 60)}`;
};

export const getFromattedPath = (template: string, values: any) => {
    return template.replace(/:([a-zA-Z0-9_]+)/g, (match, key) => {
        return values[key] || match;
    });
};
