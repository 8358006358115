export interface IModulesResponse {
    data: {
        modules: IModule[];
    };
}
export interface IModuleResponse {
    data: {
        module: IModule;
    };
}
export interface IUnitResponse {
    data: {
        unit: IModuleUnit;
    };
}
export interface IModule {
    id: string;
    title: string;
    description: string;
    tag: string;
    tag_color: string;
    contentAmount?: number;
    units?: IModuleUnit[];
    isFavorite?: boolean;
    moduleProgress?: number;
    unitsProgress?: IModuleUnitProgress[]
}

export interface IUnitItemProgress {
    type: ComponentTypes;
    timestamp: string;
    locale: string;
    completed: boolean;
    strapiItemId: string;
    progress: number;
}

export interface IUnitItem {
    type: ComponentTypes;
    id: string;
    title: string;
    duration: number;
}

export interface IModuleProgress {
    id: string;
    units: IModuleUnitProgress[];
}

export interface IModuleUnitProgress {
    id: string;
    items: IUnitItemProgress[];
    completedItems: number;
}

export interface IModuleUnit {
    id: string;
    title: string;
    description: string;
    tag: string;
    tag_color: string;
    type: ComponentTypes;
    items: IUnitItem[];
}

export enum ComponentTypes {
    VIDEO = "ComponentItemVideo",
    FORM = "ComponentItemTypeForm",
    RICH_TEXT = "ComponentItemRichText",
    IMAGE = "ComponentItemImage",
    FILE = "ComponentItemFile",
    AUDIO = "ComponentItemAudio",
    INFO_BANNER = "ComponentItemInfoBanner",
    IMAGE_SLIDER = "ComponentItemSliderImage"
}

export enum ItemTypes {
    AUDIO = "AUDIO",
    FILE = "FILE",
    IMAGE = "IMAGE",
    IMAGE_SLIDER = "IMAGE_SLIDER",
    RICH_TEXT = "TEXT",
    FORM = "TYPEFORM",
    VIDEO = "VIDEO"
}

export enum ExternalPlatforms {
    VIMEO = "VIMEO",
    TYPEFORM = "TYPEFORM"
}

export interface IProgress {
    unitId: string;
    itemId: string;
    progress: number;
    itemType: ItemTypes;
    itemLocale: string;
    externalPlatform?: ExternalPlatforms;
    externalId?: string;
    externalData?: string;
}

export interface IModuleSetProgressPayload extends IProgress {
    moduleId: string;
}
export interface IModuleGetProgressPayload {
    moduleId: string;
    locale: string;
}

export interface IUnitItemProgressResponse {
    id: string;
    employeeId: string;
    moduleId: string;
    unitId: string;
    itemId: string;
    itemType: string;
    itemLocale: string;
    externalPlatform: string;
    externalId: string;
    externalData: string;
    progress: number;
    timestamp: Date;
}

export interface IModuleProgressResponse {
    moduleId: string;
    units: IModuleUnit[];
}

export interface IModuleFavoriteResponse {
    strapiModuleId: string;
    favourite: boolean;
}
