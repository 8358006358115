import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { IInfoBannerProps } from "./InfoBanner.interface";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import Link from "../../Link/Link";

function InfoBanner(props: IInfoBannerProps) {

    return (
        <section className="unit_item no_margins container-fluid gx-2 bg-green-100 px-1 py-2">
            <div className="row gx-0">
                <div className="col-12">
                    <h2 className="text-h4-bd mb-02">{props.title}</h2>
                    <p className="text-bd2-rg color-neutrals-dark">{props.description}</p>

                    <Link
                        target={props.link.target}
                        to={props.link.href}
                        variant="link"
                        customCss="justify-content-start"
                        icon={props.icon}
                        hasChevron>
                        {props.link.text}
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default InfoBanner;
