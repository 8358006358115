import { useContext } from "react";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import { IFavoritesProps } from "./Favorites.interface";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { ModuleContext } from "../../shared/providers/module/module.provider";

function Favorites(props: IFavoritesProps) {
    const { translate } = useContext(TranslationContext);
    const { modules } = useContext(ModuleContext);

    return (
        <div className="container-fluid gx-2 pt-2 pb-5">
            <div className="row g-1">
                <div className="col-12">
                    <h1 className="text-h3-smbd pb-1">
                        {translate("common.favorites", "Favorites")}
                    </h1>
                </div>
                <section className="col-12">
                    <div className="row g-1">
                        {modules?.map((module) => (
                            <>
                                {module.isFavorite && (
                                    <div className="col-12 col-md-6 col-lg-3" key={module.id}>
                                        <ModuleCard
                                            {...module}
                                            title={module.title}
                                            contentAmount={module.contentAmount}
                                            description={module.description}
                                        />
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Favorites;
